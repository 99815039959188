























import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const UserModule = namespace("UserModule");
@Component({
  components: {
    Settings: () => import("@/components/Menu/Settings.vue"),
    BtnHelp: () => import("@/components/BtnHelp.vue")
  }
})
export default class BarApp extends Vue {
  @UserModule.Getter
  public getPoints!: number;
}
